$colors: 250;

.product-list-container {
  max-width: 1020px;
  margin: auto;
  padding: 5em 3em;

  @media (max-width: 700px) {
    padding: 3em 1em;
  }
}
.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding: 0;
  @media (max-width: 1000px) {
    justify-content: space-around;
  }

  &__link {
    text-decoration: none;
    color: #358ed7;
  }

  .product-item{
    @for $i from 0 through $colors {
      .color-choose input[type='radio'] + label span.color-9-#{$i} {
        background-position-x: -3px;
        background-position-y: 24px;
      }
    }
    @for $i from 0 through $colors {
      .color-choose input[type='radio'] + label span.color-3-#{$i} {
        background-position-x: -3px;
        background-position-y: 24px;
      }
    }
    @for $i from 0 through $colors {
      .color-choose input[type='radio'] + label span.color-29-#{$i} {
        background-position-x: -3px;
        background-position-y: 24px;

      }
    }
  }
}

.sticky-wrapper {
  position: relative;
}
.sticky {
  position: sticky;
  top: 0;
  z-index: 100; /* this is optional and should be different for every project */
  background: white;
  height: auto;
  border-bottom: 1px solid #ccc;
  padding: 1em 0 0;
}
.sticky .sticky-inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
