.header {
  background-color: #ccc;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
  color: black;
  padding: 1em;
  @media (max-width: 1000px) {
    padding: 0.5em;
  }
  img {
    margin-right: 0.4em;
    width: 120px;
  }
}
