$colors: 250;

.product-detail {
  display: flex;
  flex-direction: column;
  width: 320px;

  &__image {
    width: 100%;
  }

  &__name {
    text-align: left;
    padding: 1em 0;
    font-size: 20px;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px;
  display: flex;
}
.left-column {
  width: 50%;
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.right-column {
  width: 50%;
  margin-top: 20px;
}

/* Left Column */
.left-column img {
  transition: all 0.3s ease;
  width: 400px;
  margin-right: 2em;

  &.mendi-product {
    width: 400px;
  }
}

.left-column img.active {
  opacity: 1;
}

/* Product Description */
.product-description {
  border-bottom: 1px solid #e1e8ee;
  margin-bottom: 20px;

  &__category-link {
    text-decoration: none;
  }
}
.product-description span {
  font-size: 12px;
  color: #358ed7;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}
.product-description h1 {
  font-weight: 300;
  font-size: 52px;
  color: #43484d;
  letter-spacing: -2px;
}
.product-description p {
  font-size: 16px;
  font-weight: 300;
  color: #86939e;
  line-height: 24px;
}

p.product-ref,
span.product-line {
  font-size: 28px;
  font-weight: bold;
  color: #86939e;
  line-height: 24px;
  text-transform: none;
}

.product-sizes__link a {
  color: #86939e;
}

/* Product Color */
.product-feature {
  margin-bottom: 30px;
  border-bottom: 1px solid #e1e8ee;
  padding-bottom: 1em;
  &__title {
    margin-bottom: 12px;
    display: block;
  }

  &__color-name {
    margin: 1em 0 0;
    display: block;
  }
  &__price-container {
    display: flex;
    flex-direction: column;
  }
  &__price {
    font-size: 21px;
    font-weight: bold;
  }
  &__price-vat {
    font-size: 10px;
    text-transform: uppercase;
  }
}

.color-choose div {
  display: inline-block;
}

.color-choose input[type='radio'] {
  display: none;
}

.color-choose input[type='radio'] + label span {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
}
.color-choose.color-choose--small input[type='radio'] + label span {
  width: 20px;
  height: 20px;
}

.color-choose input[type='radio'] + label span {
  border: 2px solid #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}

.color-choose input[type='radio']#red + label span {
  background-color: #c91524;
}
.color-choose input[type='radio']#blue + label span {
  background-color: #314780;
}
.color-choose input[type='radio']#black + label span {
  background-color: #323232;
}

// .color-choose input[type=radio]:checked + label span {
//   // background-repeat: no-repeat;
//   // background-position: center;
// }

@mixin colors-generator {
  @for $i from 0 through $colors {
    .color-choose input[type='radio'] + label span.color-#{$i} {
      //background-image: url('/colors/' + $i + '.png');
      background-position: 50% 50%;
    }
  }
}
@mixin mixed-colors-generator {
  @for $i from 0 through $colors {
    .color-choose input[type='radio'] + label span.color-19-#{$i} {
      //background-image: url('/colors/19-' + $i + '.png');
      background-position: 50% 50%;
    }
  }
  @for $i from 0 through $colors {
    .color-choose input[type='radio'] + label span.color-20-#{$i} {
      //background-image: url('/colors/20-' + $i + '.png');
      background-position: 50% 50%;
    }
  }
  @for $i from 0 through $colors {
    .color-choose input[type='radio'] + label span.color-0-#{$i} {
      //background-image: url('/colors/0-' + $i + '.png');
      background-position: 50% 50%;
    }
  }
  .color-choose input[type='radio'] + label span.color-0-0 {
    background: black;
  }
  @for $i from 0 through $colors {
    .color-choose input[type='radio'] + label span.color-8-#{$i} {
      //background-image: url('/colors/8-' + $i + '.png');
      background-position: 50% 50%;
    }
  }
  @for $i from 0 through $colors {
    .color-choose input[type='radio'] + label span.color-61-#{$i} {
      //background-image: url('/colors/61-' + $i + '.png');
      background-position: 50% 50%;
    }
  }
  @for $i from 0 through $colors {
    .color-choose input[type='radio'] + label span.color-210-#{$i} {
      //background-image: url('/colors/210-' + $i + '.png');
      background-position: 50% 50%;
    }
  }
  @for $i from 0 through $colors {
    .color-choose input[type='radio'] + label span.color-1-#{$i} {
      //background-image: url('/colors/1-' + $i + '.png');
      background-position: 50% 50%;
    }
  }
  @for $i from 0 through $colors {
    .color-choose input[type='radio'] + label span.color-2-#{$i} {
      //background-image: url('/colors/2-' + $i + '.png');
      background-position: 50% 50%;
    }
  }
  @for $i from 0 through $colors {
    .color-choose input[type='radio'] + label span.color-18-#{$i} {
      //background-image: url('/colors/18-' + $i + '.png');
      background-position: 50% 50%;
    }
  }
  @for $i from 0 through $colors {
    .color-choose input[type='radio'] + label span.color-12-#{$i} {
      //background-image: url('/colors/12-' + $i + '.png');
      background-position: 50% 50%;
    }
  }
  @for $i from 0 through $colors {
    .color-choose input[type='radio'] + label span.color-9-#{$i} {
      //background-image: url('/colors/9-' + $i + '.png');
      background-position-x: 3px;
      background-position-y: 3px;
    }
  }
  @for $i from 0 through $colors {
    .color-choose input[type='radio'] + label span.color-3-#{$i} {
      //background-image: url('/colors/3-' + $i + '.png');
      background-position-x: 3px;
      background-position-y: 3px;
    }
  }
  @for $i from 0 through $colors {
    .color-choose input[type='radio'] + label span.color-29-#{$i} {
      //background-image: url('/colors/29-' + $i + '.png');
      background-position-x: 3px;
      background-position-y: 3px;
    }
  }
  @for $i from 0 through $colors {
    .color-choose input[type='radio'] + label span.color-25-#{$i} {
      //background-image: url('/colors/25-' + $i + '.png');
      background-position: 50% 50%;
    }
  }
  @for $i from 0 through $colors {
    .color-choose input[type='radio'] + label span.color-46-#{$i} {
      //background-image: url('/colors/46-' + $i + '.png');
      background-position: 50% 50%;
    }
  }
}

@include colors-generator();
@include mixed-colors-generator();

/* Responsive */
@media (max-width: 940px) {
  .container {
    flex-direction: column;
    margin-top: 60px;
  }

  .left-column {
    min-height: 440px;
  }
  .left-column,
  .right-column {
    width: 100%;
  }

  .left-column img {
    width: 300px;
    right: 0;
    top: -65px;
    left: initial;

    &.mendi-product {
      width: 530px;
    }
  }
}

@media (max-width: 535px) {
  .left-column img {
    width: 220px;
    top: -85px;
    &.mendi-product {
      width: 360px;
    }
  }
}
