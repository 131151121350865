.sizes {
  display: flex;
  padding: 1em;
  flex-direction: column;
  max-width: 900px;
  margin: auto;

  &__text {
    font-size: 20px;
  }
  &__image {
    margin: auto;
  }

  &__header {
    font-size: 45px;
  }

  &__title {
    text-align: center;
    font-size: 35px;
    font-weight: bold;
  }
}
