.searcher {
  display: flex;
  position: relative;
  &__input {
    width: 97%;
    border: 1px solid #ccc;
    padding: 0.5em 2em 0.5em 0.5em;
    font-size: 1.2em;
    border-radius: 10px;
  }
  &__icon {
    position: absolute;
    width: 22px;
    height: 1.7em;
    right: 0;
    top: 0;
    padding: 0.5em;

    img {
      width: 100%;
    }
  }
}
