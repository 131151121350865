@import './styles/fonts.scss';
@import './styles/product-list.scss';
@import './styles/product-item.scss';
@import './styles/product-details.scss';
@import './styles/catalog.scss';
@import './styles/sizes.scss';
@import './styles/header.scss';
@import './styles/footer.scss';
@import './styles/protection.scss';
@import './styles/searcher.scss';
@import './styles/normativa.scss';

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-section {
  max-width: 1280px;
  margin: auto;
}
