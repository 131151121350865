ul.protection-list {
  display: flex;
  list-style: none;
  padding: 1em;
  background: #ccc;

  li > img {
    margin-right: 1em;
    width: 48px;
  }
}
