.footer {
  display: flex;
  font-size: 20px;
  justify-content: center;
  background: #ccc;
  padding: 1.2em;
  @media (max-width: 700px) {
    flex-direction: column;
  }

  &__title {
    font-weight: bold;
  }
  &__col-left {
    margin-right: 3em;
    img {
      width: 300px;
    }
  }
  &__col-right {
    margin-left: 3em;
    p {
      margin-bottom: 0;
      margin-top: 0.5em;
    }
  }

  &__phones {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    span {
      margin-right: 1em;
    }
  }
}
