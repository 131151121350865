.categories {
  display: flex;
  justify-content: space-between;
  padding: 0em;
  flex-direction: column;

  &__item {
    list-style: none;
    padding: 1em;
    font-size: 1.6em;
    text-decoration: none;
    color: black;
    &:hover {
      background: #e1e8ee;
    }
    a {
    }
  }

  &__title {
    margin-left: 1em;
  }
}
