.product-item {
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 0 10px 30px 10px;
  border: 1px solid #e1e8ee;
  padding: 1em;
  text-decoration: none;
  color: black;

  &:hover {
    background: #eee;
  }

  &__ref {
    margin: 0.5em 0 0;
  }
  &__image {
    width: 100%;
  }

  &__image-container {
    min-height: 245px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__name {
    text-align: left;
    font-size: 18px;
    margin: 12px 0;
    width: 100%;
    display: block;
    min-height: 50px;
  }
  &__sizes {
    padding: 1em 0 0 0;
    display: block;
    font-size: 14px;
  }
}
