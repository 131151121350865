.leyenda-container {
  display: flex;

  .leyenda-norma {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    border-top: 1px solid #909090;

    .pictograma-inner {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      flex: 2;

      img {
        margin: 5px;
      }
    }
  }
  .iso-norma-txt {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    flex: 1;
    p {
      margin: 0;
    }
  }
  .puntera-plantilla-inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    flex: 2;

    img {
      margin: 10px;
    }
  }
}
.definiciones-punteras-plantillas {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.definiciones-punteras-plantillas .definiciones-punteras,
.definiciones-punteras-plantillas .definiciones-plantillas {
  margin-left: 15px;
  margin-right: 15px;
}

.definiciones-punteras-plantillas .definicion-planpun-title {
  font-weight: 700;
}
.definiciones-punteras-plantillas .definicion-planpun-inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.definiciones-punteras-plantillas .definicion-planpun-inner,
.definiciones-punteras-plantillas .definicion-planpun-title {
  margin-bottom: 10px;
}
.definiciones-punteras-plantillas .definicion-planpun-inner img {
  margin-right: 15px;
}
.pictogramas-leyenda p {
  margin-bottom: 0px;
  font-size: 15px;
}
.definiciones-pictogramas {
  display: flex;
}
.definiciones-pictograma-inner {
  padding-left: 15px;
  padding-right: 15px;
}
.definiciones-pictogramas .definicion-pictograma {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}
.definiciones-pictogramas .definicion-pictograma img {
  margin-right: 15px;
}
.pictogramas-leyenda p {
  margin-bottom: 0px;
  font-size: 15px;
}
.definicion-pictograma-txt p {
  font-size: 15px;
}
.definiciones-pictogramas .definicion-pictograma {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}
.definicion-src .definicion-pictograma-img {
  display: flex;
  align-items: center;
}
.definiciones-pictogramas .definicion-pictograma img {
  margin-right: 15px;
}
.iso-leyenda .iso-title,
.definiciones-title {
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.leyenda-container .leyenda-norma {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  border-top: 1px solid #909090;
}
.pictogramas-leyenda .puntera-plantilla-inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex: 2;
}
.pictogramas-leyenda .leyenda-norma .pictograma-inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex: 2;
}
.pictogramas-leyenda .leyenda-norma .pictograma-inner img {
  margin: 5px;
}

.col-12,
.col-xs-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto,
.col-xs-12 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
}
